import React from "react";
import Close from "../../../static/icons/common/close.svg";
import MenuArrow from "../../../static/icons/common/menu-arrow.svg";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useNoScroll } from "../../hooks/useNoScroll";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { styled } from "../../../stitches.config";
import { Category } from "../../types";

type Props = {
  toggle: () => void;
  isOpen: boolean;
  categories: Category[];
};

export const CategoriesMobileMenu = ({ toggle, isOpen, categories }: Props) => {
  const { t } = useTranslation();
  const { ref } = useClickOutside({ onClickOutside: toggle, isOpen });
  const { navigate } = useI18next();

  useNoScroll();

  return (
    <>
      <Backdrop disabled={!isOpen}>
        <MobileMenuContainer
          scrollable={isOpen}
          ref={(interalRef) => (ref.current = interalRef)}
        >
          <MobileMenuHead>
            <MenuActionBtn
              css={{ marginLeft: "auto" }}
              onClick={() => {
                toggle();
              }}
            >
              <Close />
            </MenuActionBtn>
          </MobileMenuHead>
          <div>
            <CategoryTitle>{t("categories")}</CategoryTitle>
            <nav>
              <CategoriesList>
                {categories.map((item, i) => (
                  <MobileMenuEl
                    onClick={() => {
                      navigate(
                        `/news/categories/${item.attributes.categoryId}`
                      );
                      toggle();
                    }}
                    key={i}
                  >
                    <span>{item.attributes.categoryName}</span>
                    <MenuArrow />
                  </MobileMenuEl>
                ))}
              </CategoriesList>
            </nav>
          </div>
        </MobileMenuContainer>
      </Backdrop>
    </>
  );
};

const MobileMenuContainer = styled("div", {
  position: "fixed",
  backgroundColor: "$white",
  height: "100vh",
  width: "87vw",
  top: 0,
  right: 0,
  zIndex: 15,
  padding: "24px 16px 16px 16px",
  display: "flex",
  flexDirection: "column",
  variants: {
    scrollable: {
      true: {
        overflow: "auto",
      },
    },
  },
});

const MobileMenuHead = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "24px",
});

export const MenuActionBtn = styled("button", {
  border: "none",
  backgroundColor: "$white",
  color: "$text",
  transition: "fill 0.5s ease",
  "&:hover": {
    fill: "$accent",
  },
  "@md": {
    display: "none",
  },
});

const Backdrop = styled("div", {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  zIndex: 15,
  left: 0,
  top: 0,
  backgroundColor: "rgba(0,0,0, 0.5)",
  "@md": { display: "none" },
  variants: {
    disabled: {
      true: {
        display: "none",
      },
    },
  },
});

const CategoriesList = styled("ul", {
  listStyle: "none",
  padding: 0,
  margin: 0,
});

const CategoryTitle = styled("span", {
  color: "#263238",
  fontWeight: 700,
  lineHeight: "28px",
  fontSize: "24px",
});

const MobileMenuEl = styled("li", {
  fill: "#263238",
  color: "#263238",
  padding: "12px 24px",
  marginTop: "12px",
  backgroundColor: "$secondaryBackground",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "24px",
  alignItems: "center",
  transition: "all 0.5s ease",
  "&:hover": {
    backgroundColor: "$primaryBackground",
  },
  variants: {
    main: {
      true: {
        backgroundColor: "$accent",
        fill: "$white",
        color: "$white",
        "&:hover": {
          backgroundColor: "#90B015",
        },
      },
    },
  },
});
