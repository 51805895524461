import { keyframes, styled } from "../../../stitches.config";

const loaderKeyframe = keyframes({
  "0%": {
    rotate: "0deg",
  },
  "100%": {
    rotate: "360deg",
  },
});
export const Loader = styled("span", {
  borderRadius: "50%",
  border: "5px solid $accent",
  borderBottom: "5px solid $footer",
  animation: `${loaderKeyframe} 1s linear infinite`,
  width: "48px",
  height: "48px",
  margin: "0 auto",
  variants: {
    size: {
      xl: {
        width: "100px",
        height: "100px",
        borderWidth: "10px",
        margin: "100px auto",
        "@md": {
          display: "initial",
        },
      },
    },
  },
});
