import React, { useReducer } from "react";
import { CategoriesMobileMenu } from "./CategoriesMobileMenu";
import CategoryBtn from "../../../static/icons/common/categories-menu.svg";
import { Category } from "../../types";
import { styled } from "../../../stitches.config";

type Props = {
  categories: Category[];
};
export const CategoriesMobileMenuTrigger = ({ categories }: Props) => {
  const [isOpen, toggle] = useReducer((val) => !val, false);

  return (
    <>
      <MenuActionBtn>
        <CategoryBtn onClick={toggle} />
      </MenuActionBtn>
      {isOpen && (
        <CategoriesMobileMenu
          categories={categories}
          toggle={toggle}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export const MenuActionBtn = styled("button", {
  border: "none",
  backgroundColor: "$white",
  padding: "12px",
  height: "48px",
  margin: "0px 10px 0px 20px",
  color: "$text",
  transition: "fill 0.5s ease",
  "&:hover": {
    fill: "$accent",
  },
  "@md": {
    display: "none",
  },
});
