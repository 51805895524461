import React from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { styled } from "../../../stitches.config";
import { Article, Category } from "../../types";
import { formatDate } from "../../utils";
import Arrow from "../../../static/icons/common/category-arrow.svg";

type Props = {
  categories: Category[];
  latestArticles: Article[];
  isInRow?: boolean;
};
export const CategoriesDesktop = ({
  categories,
  latestArticles,
  isInRow,
}: Props) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  return (
    <Wrap isInRow={isInRow}>
      <ListWrap isInRow={isInRow}>
        <Title>{t("categories")}</Title>
        <CategoriesList>
          {categories.map((item, i) => (
            <>
              {!!isInRow ? (
                <CategoryItem
                  onClick={() =>
                    navigate(`/news/categories/${item.attributes.categoryId}`)
                  }
                  key={i}
                >
                  <CategoryName>{item.attributes.categoryName}</CategoryName>
                  <Arrow />
                </CategoryItem>
              ) : (
                <CategoriesListEl
                  onClick={() =>
                    navigate(`/news/categories/${item.attributes.categoryId}`)
                  }
                  key={i}
                >
                  <Point />
                  <CategoryName>{item.attributes.categoryName}</CategoryName>
                </CategoriesListEl>
              )}
            </>
          ))}
        </CategoriesList>
      </ListWrap>
      <ListWrap isInRow={isInRow}>
        <Title>{t("latestNews")}</Title>
        <LatestNewsList>
          {latestArticles.map((item, i) => {
            return (
              <LatestNewsListEl
                onClick={() => navigate(`/news/${item.attributes.slug}`)}
                key={i}
              >
                <DateIndicator>
                  {formatDate(item.attributes.publicationDate)}
                </DateIndicator>
                <TitleImageContainer>
                  <ImageWrap>
                    {item.attributes.previewImage.data && (
                      <img
                        src={item.attributes.previewImage.data.attributes.url}
                      />
                    )}
                  </ImageWrap>
                  <ArticleTitle>
                    {item.attributes.title}
                  </ArticleTitle>
                </TitleImageContainer>
              </LatestNewsListEl>
            );
          })}
        </LatestNewsList>
      </ListWrap>
    </Wrap>
  );
};

const Wrap = styled("div", {
  display: "flex",
  flexDirection: "column",

  variants: {
    isInRow: {
      true: {
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      },
    },
  },
});

const CategoryItem = styled("div", {
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 30,
  padding: "12px 24px",
  background: "$secondaryBackground",
  borderRadius: 24,
  "&:not(:last-child)": {
    marginBottom: "12px",
  },
});

const ListWrap = styled("div", {
  "&:last-child": { marginTop: "34px" },

  variants: {
    isInRow: {
      true: {
        "&:last-child": { marginTop: 0 },
      },
    },
  },
});

const CategoriesList = styled("ul", {
  padding: 0,
  margin: 0,
  listStyle: "none",
  marginTop: "24px",
});

const CategoriesListEl = styled("li", {
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  "&:not(:last-child)": {
    marginBottom: "12px",
  },
});

const Point = styled("span", {
  backgroundColor: "$accent",
  display: "block",
  flexBasis: "12px",
  height: "12px",
  borderRadius: "50%",
});

const CategoryName = styled("span", {
  marginLeft: "18px",
  fontSize: "17px",
  lineHeight: "24px",
  fontWeight: 400,
});

const Title = styled("span", {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  marginLeft: "13",
});

const LatestNewsList = styled("ul", {
  padding: 0,
  margin: 0,
  listStyle: "none",
  marginTop: "24px",
});

const LatestNewsListEl = styled("li", {
  padding: "6px 0px",
  cursor: "pointer",
  "&:not(:first-child)": {
    marginTop: "12px",
  },
});

const TitleImageContainer = styled("div", {
  display: "flex",
  marginTop: "7px",
});

const DateIndicator = styled("span", {
  fontSize: "15px",
  lineHeight: "20px",
  color: "#CFCFCF",
  fontWeight: 400,
});

const ArticleTitle = styled("p", {
  margin: 0,
  color: "$heading",
  fontSize: "17px",
  lineHeight: "24px",
  fontWeight: 400,
  display: "inline-block",
});

const ImageWrap = styled("div", {
  borderRadius: "50%",
  display: "flex",
  marginRight: "13px",
  width: "22px",
  height: "23px",
  backgroundColor: "$primaryBackground",
  "& > img": {
    marginRight: "13px",
    width: "22px",
    height: "23px",
    borderRadius: "50%",
  },
});
