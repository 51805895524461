import React, { useEffect, useState } from 'react';
import { styled } from '../../../stitches.config';
import { BreadCrumbs } from '../../components/shared/BreadCrumbs';
import { Layout } from '../../layout';
import { routes } from '../../routes/routes';
import CategoryIcon from '../../../static/icons/common/category-sign.svg';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import ArrowLeft from '../../../static/icons/common/arrow-left.svg';
import ArrowRight from '../../../static/icons/common/arrow-right.svg';
import { SearchBar } from '../../components/News/SearchBar';
import { Loader } from '../../components/shared/Loader';
import { findQueue, formatDate } from '../../utils';
import { Seo } from '../../components/shared/Seo';
import { RichtextParser } from '../../components/News/RichtextParser';
import { Article } from '../../types';
import { CategoryIndicator } from '../../components/News/CategoryIndicator';

type ArticleProps = {
  article: Article;
  list: Article[];
  slug: string;
};

export const ArticlePageView: React.FC<ArticleProps> = ({
  article,
  list,
  slug,
}) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const [queue, setQueue] = useState(['', '']);

  useEffect(() => {
    findQueue({ data: list, slug, setQueue });
  }, [slug]);

  return (
    <Layout>
      {article.attributes && (
        <Seo
          title={article.attributes.title}
          description={article.attributes.description}
          image={article.attributes.image.data.attributes.url}
        />
      )}
      <Container>
        {!!article ? (
          <LeftContainer>
            <>
              <BreadCrumbs
                root={{
                  link: routes.news.news,
                  title: t('news'),
                }}
                current={article?.attributes.title}
              />
              <ImageBlock>
                <ImageWrap>
                  {article?.attributes.image.data && (
                    <img src={article?.attributes.image.data.attributes.url} />
                  )}
                </ImageWrap>
              </ImageBlock>
              <Box>
                <DateIndicator>
                  {formatDate(article.attributes.publicationDate)}
                </DateIndicator>
                {article?.attributes.category.data && (
                  <CategoryIndicator
                    categoryId={
                      article.attributes.category.data.attributes.categoryId
                    }
                    categoryName={
                      article.attributes.category.data.attributes.categoryName
                    }
                  />
                )}
              </Box>
              <ArticleTitle>{article?.attributes.title}</ArticleTitle>
              <RichtextParser description={article?.attributes.description} />

              <Navigation>
                <Prev
                  onClick={() => navigate(`/news/${queue[0]}`)}
                  locked={!queue[0]}
                >
                  <ArrowLeft />
                  {t('article.nav.prev')}
                </Prev>
                <Next
                  onClick={() => navigate(`/news/${queue[1]}`)}
                  locked={!queue[1]}
                >
                  {t('article.nav.next')}
                  <ArrowRight />
                </Next>
              </Navigation>
            </>
          </LeftContainer>
        ) : (
          <Loader size={'xl'} />
        )}
        <RightContainer>
          <SearchBar />
        </RightContainer>
      </Container>
    </Layout>
  );
};

const Container = styled('div', {
  padding: '60px 16px 80px',
  maxWidth: '1280px',
  display: 'flex',
  flexDirection: 'column-reverse',
  margin: '0 auto',
  '@md': {
    flexDirection: 'row',
    padding: '80px 0 124px',
    maxWidth: '1280px',
    minHeight: '800px',
  },
});

const Category = styled(Link, {
  color: '#9F9F9F',
  fill: '#9F9F9F',
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  textDecoration: 'none',
  '&> span': {
    margin: '0px 0px 0px 4px',
    fontSize: '16px',
  },
  '@md': { fontSize: 'unset' },
  '&:hover': {
    fill: '$accent',
    color: '$accent',
  },
});

const Prev = styled('span', {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  fontWeight: 700,
  userSelect: 'none',
  fontSize: '17px',
  lineHeight: '24px',
  color: '$footer',
  cursor: 'pointer',
  transition: 'all 300ms ease',
  '&:hover': {
    transition: 'all 300ms ease',
    color: '$accent',
    '&>svg': {
      transition: 'all 300ms ease',
      '&>g': {
        transition: 'all 300ms ease',
        opacity: 1,
        '&>path': {
          transition: 'all 300ms ease',
          fill: '$accent',
        },
        '&>rect': {
          transition: 'all 300ms ease',
          stroke: '$accent',
        },
      },
    },
  },
  variants: {
    locked: {
      true: {
        visibility: 'hidden',
      },
    },
  },
});

const Next = styled('span', {
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  gap: 12,
  fontWeight: 700,
  fontSize: '17px',
  lineHeight: '24px',
  color: '$footer',
  cursor: 'pointer',
  transition: 'all 300ms ease',
  '&:hover': {
    transition: 'all 300ms ease',
    color: '$accent',
    '&>svg': {
      transition: 'all 300ms ease',
      '&>g': {
        transition: 'all 300ms ease',
        opacity: 1,
        '&>path': {
          transition: 'all 300ms ease',
          fill: '$accent',
        },
        '&>rect': {
          transition: 'all 300ms ease',
          stroke: '$accent',
        },
      },
    },
  },
  variants: {
    locked: {
      true: {
        visibility: 'hidden',
      },
    },
  },
});

const Navigation = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '32px 0 80px',

  '@md': {
    margin: '66px 0 0',
  },
});

const BottomCategory = styled(Link, {
  cursor: 'pointer',
  margin: 0,
  fontWeight: 400,
  textDecoration: 'none',
  fontSize: '15px',
  lineHeight: '20px',
  color: '$articleDate',
  fill: '$articleDate',
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  '&:hover': {
    fill: '$accent',
    color: '$accent',
  },
});

const Box = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '14px 0 32px',
});

const LeftContainer = styled('div', {
  width: '100%',
  '@md': {
    flexBasis: 'calc(100% - 501px)',
  },
});

const DateIndicator = styled('p', {
  margin: 0,
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  color: '$articleDate',
});

const ImageWrap = styled('div', {
  borderRadius: '6px',
  margin: '0 auto',
  height: 'fit-content',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > img': {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '5px',
  },
  '@md': {
    justifyContent: 'start',

    margin: 0,
  },
});

const ImageBlock = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  '@md': {
    justifyContent: 'flex-start',
  },
});

const ArticleTitle = styled('h1', {
  margin: '0 0 24px',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '40px',
  '@md': {
    fontSize: '48px',
    lineHeight: '56px',
  },
});

const Text = styled('p', {
  margin: '0 0 18px',
  fontWeight: 400,
  fontSize: '17px',
  lineHeight: '24px',
});

const RightContainer = styled('div', {
  marginBottom: '40px',
  '@md': {
    marginBottom: '0px',
    flexBasis: '501px',
  },
});

const CategoryDateWrap = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 24,
  marginBottom: 0,
});
