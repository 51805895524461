import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { styled } from "../../../stitches.config";
import ElIcon from "../../../static/icons/common/category-sign.svg";

type Props = {
  categoryId: string;
  categoryName: string;
};

export const CategoryIndicator: React.FC<Props> = ({
  categoryId,
  categoryName,
}) => {
  return (
    <Category to={`/news/categories/${categoryId}`}>
      <ElIcon />
      <span>{categoryName}</span>
    </Category>
  );
};

const Category = styled(Link, {
  color: "#9F9F9F",
  fill: "#9F9F9F",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  "&> span": {
    margin: "0px 0px 0px 4px",
    fontSize: "16px",
  },
  "@md": { fontSize: "unset" },
  "&:hover": {
    fill: "$accent",
    color: "$accent",
  },
});
