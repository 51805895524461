import React, { useEffect } from "react";
import { GetServerDataProps, graphql, PageProps } from "gatsby";
import { ArticlePageView } from "../../views/ArticlePageView/ArticlePageView";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { Article } from "../../types";
import { useI18next } from "gatsby-plugin-react-i18next";

export type ArticlePageData = {
  list: Article[];
  article: Article;
  slug: string;
  notFound: boolean;
};

const ArticlePage: React.FC<PageProps> = ({ serverData }) => {
  const { navigate } = useI18next();
  const { article, slug, list, notFound } = serverData as ArticlePageData;

  useEffect(() => {
    notFound && navigate("/404", { replace: true });
  }, [notFound]);

  if (notFound) {
    return null;
  }
  return <ArticlePageView article={article} slug={slug} list={list} />;
};

interface ContextParams {
  [key: string]: string;
}

export async function getServerData(context: GetServerDataProps) {
  const { id } = context.params as ContextParams;

  const getData = async () => {
    try {
      const { data } = await sendRequestToAPI(
        `
       query($slug: String, $locale: I18NLocaleCode){
        articles(locale: $locale, filters:{slug:{eq: $slug }}){
        data{
          attributes{
            category{data{attributes{
              categoryName 
              categoryId}}}
            slug
            description
            image{data{attributes{url}}}
            publicationDate
            title
          }
        }
        }
        list: articles{
          data{attributes{
          slug
          }}
          }
      }
      `,
        { slug: id, locale: context.pageContext.language },
        {},
        process.env.GATSBY_API_URL
      );
      return data;
    } catch (err) {
      return err;
    }
  };
  const { data } = await getData();

  const notFound = !data.articles?.data?.length;

  const pageData: ArticlePageData = {
    article: data.articles.data[0],
    list: data.list.data,
    slug: id,
    notFound,
  };

  return {
    props: { ...pageData },
  };
}

export default ArticlePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
